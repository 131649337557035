import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Loader from './components/Helper/Loader';
import ScrollToTop from './components/Helper/ScrollToTop';

const Error = lazy(() => import('./components/Error'));
const Home = lazy(() => import('./mypages'));
// const DoctorPage = lazy(() => import('./mypages/DoctorPage'));
// const NewsPage = lazy(() => import('./mypages/NewsPage'));
// const ContactPage = lazy(() => import('./mypages/ContactPage'));

const Routes = () => {
    const [loading, setLoading] = useState(true);

    const hashLinkScroll = () => {};
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);
    return (
        <>
            {loading ? (
                <div className="appie-loader active">
                    <Loader />
                </div>
            ) : (
                <>
                    <div className="appie-visible active">
                        <Router onUpdate={hashLinkScroll}>
                            <Suspense
                                fallback={
                                    <div className="appie-loader active">
                                        <Loader />
                                    </div>
                                }
                            >
                                <ScrollToTop>
                                    <Switch>
                                        <Route exact path="/" component={Home} />
                                        <Route exact path="/:pathname" component={Home} />
                                        {/* <Route exact path="/doctor/:name" component={DoctorPage} />
                                        <Route exact path="/news" component={NewsPage} /> */}
                                        <Route component={Error} />
                                    </Switch>
                                </ScrollToTop>
                            </Suspense>
                        </Router>
                    </div>
                </>
            )}
        </>
    );
};

export default Routes;
